<template>
  <!-- 修改简历模拟面试 -->
  <div class="mockInterview">
    <div class="pagebanner">
      <img src="@/assets/mock/resumeGuidance/indexBanner.png" alt="" />
      <div class="pageTitle">
        1v1深挖修改简历
        <div class="subtitle">
          一份好的简历是求职路上的不可或缺的敲门砖
          <br />
          一针见血指出问题，逐行逐句修改，简历通过率轻松提高75%
        </div>
      </div>
    </div>
    <div class="containers">
      <div class="g-section">
        <div class="g-header">
          <h3>1v1简历修改指导，深入挖掘自己的潜力</h3>
          <p>简历内容重新塑造，挖掘自己看不见的点</p>
        </div>
        <div class="plans">
          <div class="plan">
            <img src="@/assets/mock/resumeGuidance/plan1.png" alt="" />
            <div class="plan-rightText">
              <div class="plan-title">简历内容重塑</div>
              <div class="plan-description">
                根据你的个人背景经历和岗位申请方向，让每一段经历既符合职位要求，突出个人优势
              </div>
            </div>
          </div>
          <div class="plan">
            <img src="@/assets/mock/resumeGuidance/plan2.png" alt="" />
            <div class="plan-rightText">
              <div class="plan-title">个人项目指导</div>
              <div class="plan-description">
                针对不同岗位，提供项目针对性选择指导
                <br />
                项目重点提炼，符合目标岗位竞争力
              </div>
            </div>
          </div>
          <div class="plan">
            <img src="@/assets/mock/resumeGuidance/plan3.png" alt="" />
            <div class="plan-rightText">
              <div class="plan-title">转专业岗位差异化充实</div>
              <div class="plan-description">
                转行转专业简历如何充实内容，提升竞争力
                <br />
                深入挖掘项目潜力，不无内容可写
              </div>
            </div>
          </div>
          <div class="plan">
            <img src="@/assets/mock/resumeGuidance/plan4.png" alt="" />
            <div class="plan-rightText">
              <div class="plan-title">格式规范内容</div>
              <div class="plan-description">
                整体简历风格格式，工业级简历
                <br />
                让你的简历更专业，更符合于北美求职
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="g-section">
        <div class="g-header">
          <h3>拆解简历框架，每一部分逐一分析</h3>
          <p>一点点剖析简历，每一部分个个击破</p>
        </div>
        <div class="six-points">
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            框架
          </div>
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            LaTeX
          </div>
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            Contact &amp; Education
          </div>
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            Experience &amp; Project
          </div>
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            注意事项
          </div>
          <div class="points">
            <img src="@/assets/mock/mockInterview/icon3.png" alt="" />
            <br />
            Skills
          </div>
        </div>
      </div>

      <div class="g-section">
        <div class="g-header">
          <h3>为你彻底解决简历难题</h3>
        </div>
        <div class="hardPoint">
          <el-row>
            <el-col :span="12">
              <ul class="list">
                <li>简历总是通不过初筛问题出在哪？</li>
                <li>太水 / 打杂的实习经历如何通过深挖显得高大上？</li>
                <li>每个Bullet要怎么写才能显得有影响力？</li>
                <li>如何提高简历相关性，斩获面试？</li>
                <li>为什么我的简历投递出去总是没有回应？</li>
                <li>没有项目经验怎么办？</li>
              </ul>
            </el-col>
            <el-col :span="12">
              <div class="rightlist">
                <div class="round">专业</div>
                <div class="rightText">
                  紧密结合大厂简历筛选条件要求
                  <br />
                  通过背景塑造、关键词、语言精修，全方位提升简历通过率
                </div>
              </div>
              <div class="rightlist">
                <div class="round">个性化</div>
                <div class="rightText">
                  拒绝一封简历涵盖所有投递岗位
                  <br />
                  根据岗位特性，改写简历相关性
                </div>
              </div>
              <div class="rightlist">
                <div class="round">改写</div>
                <div class="rightText">
                  每一封简历，指导修改到满意为止
                  <br />
                  得体的匹配目标岗位申请需求
                </div>
              </div>
              <div class="rightlist">
                <div class="round">全面</div>
                <div class="rightText">
                  全面梳理优势特点，凸显竞争力
                  <br />
                  打造专属你的优质简历
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="g-section gray">
        <div class="g-header">
          <h3>服务形式</h3>
          <div class="title-description">修改简历（60-90分钟、1对1）</div>
          <div class="title-description">
            Online模式：无地区限制，使用Skype远程音频
          </div>
        </div>
      </div>
      <div class="g-section">
        <div class="g-header">
          <h3>快速报名，获取你的专属定制面试策略</h3>
        </div>
        <div class="fourSteps">
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon1.png"
              alt=""
            />
            <br />
            免费咨询
            <br />
            定制专属服务
          </div>
          <div class="arrow">
            <img src="@/assets/mock/mockInterview/arrow.png" alt="" />
          </div>
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon2.png"
              alt=""
            />
            <br />
            在线支付
          </div>
          <div class="arrow">
            <img src="@/assets/mock/mockInterview/arrow.png" alt="" />
          </div>
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon3.png"
              alt=""
            />
            <br />
            修改简历
            <br />
            精准推荐面试官
          </div>
          <div class="arrow">
            <img src="@/assets/mock/mockInterview/arrow.png" alt="" />
          </div>
          <div class="step">
            <img
              class="getInIcon"
              src="@/assets/mock/mockInterview/icon4.png"
              alt=""
            />
            <br />
            1v1结构化修改
            <br />
            收获完整最终简历
          </div>
        </div>
      </div>

      <div class="priceBanner">
        <div class="priceBannerArea">
          <div class="priceBannerContent">
            <img src="@/assets/imgs/priceBanner.png" alt="" />
            <div class="priceBannerInner">
              <div class="priceLeft">
                <div class="prices">
                  <span class="mainTitle">{{ classInfo.courseName }}</span>
                  <span class="subTitle">服务价格：</span>
                  <span
                    style="
                      font-size: 28px;
                      color: #de1306;
                      line-height: 42px;
                      vertical-align: top;
                    "
                  >
                    ${{ classInfo.usdAmount }}</span
                  >
                  <span
                    style="
                      font-size: 16px;
                      color: #e02020;
                      line-height: 42px;
                      vertical-align: top;
                    "
                    >&nbsp;/&nbsp;&yen;{{ classInfo.amount }}</span
                  >
                </div>
              </div>
              <div class="priceRight">
                <div class="pricesTips">
                  <div class="dollar">支持美元分期付款</div>
                  <div class="huabei">支持花呗分期</div>
                </div>
                <el-button class="buyNow" @click="provideDialogVisible = true"
                  >立即购买</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="provideDialogVisible">
      <div class="provideContainer">
        <div class="type" @click="chooseCombo(classInfo.details[0])">
          <img src="@/assets/mock/resumeGuidance/icon1.png" alt="" />
          <div class="title">{{ classInfo.details[0].courseName }}</div>
          <div class="description">{{ classInfo.details[0].brief }}</div>
          <div class="price">${{ classInfo.details[0].usdAmount }}</div>
        </div>
        <div class="type" @click="chooseCombo(classInfo.details[1])">
          <img src="@/assets/mock/resumeGuidance/icon2.png" alt="" />
          <div class="title">{{ classInfo.details[1].courseName }}</div>
          <div class="description">{{ classInfo.details[1].brief }}</div>
          <div class="price">${{ classInfo.details[1].usdAmount }}</div>
        </div>
      </div>
    </el-dialog>
	<leetDialog></leetDialog>
  </div>
</template>
<script>
import mockMix from "@/utils/mockMix";
import leetDialog from "@/components/leetDialog.vue";
export default {
  
  components: {
  	leetDialog
  },
  data() {
    return {
      provideDialogVisible: false,
    };
  },
  mixins: [mockMix],
  methods: {
    chooseCombo(item) {
      this.selectCombo = item;
      this.buyCombo();
    },
  },
};
</script>
<style scoped lang="scss">
.pagebanner {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    vertical-align: top;
  }
  .pageTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 48px;
    .subtitle {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      margin-top: 20px;
    }
  }
}

.g-section {
  width: 100%;
  margin: 0 auto;
  padding: 36px 0;
  background: #f8f8f8;
  &.gray {
    background: rgba(240, 241, 242, 0.8);
  }
  .g-header {
    text-align: center;
    margin-bottom: 30px;
  }

  .g-header h3 {
    font-size: 32px;
    margin-bottom: 12px;
  }

  .g-header p {
    margin-top: 8px;
    color: #676767;
    font-size: 16px;
    margin-bottom: 48px;
  }
}

.plans {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .plan {
    width: 520px;
    padding: 36px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(120, 120, 128, 0.2);
    margin-bottom: 30px;
    img {
      vertical-align: top;
      width: 146px;
    }
    .plan-rightText {
      display: inline-block;
      width: calc(100% - 146px);
      .plan-title {
        padding: 16px 0;
        padding-top: 32px;
        font-size: 16px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #e59f3c;
        line-height: 24px;
      }
      .plan-description {
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.4);
        line-height: 20px;
      }
    }
  }
}
.threeInterviews {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  .interviews {
    text-align: center;
  }
  .interviewIcon {
    background: #ffffff;
    box-shadow: 0px 6px 10px 0px rgba(95, 101, 105, 0.15);
    border-radius: 8px;
    padding: 30px;
    margin: 0 60px;
    img {
      width: 42px;
      height: 42px;
      vertical-align: top;
    }
  }
  .interviewType {
    margin-top: 20px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #e59f3c;
    line-height: 27px;
  }
}
.fourSteps {
  margin-top: 40px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  .arrow,
  .step {
    margin: 0 24px;
  }
  .step {
    text-align: center;
    padding: 62px 54px;
    background: #ffffff;
    box-shadow: 0px 6px 10px 0px rgba(95, 101, 105, 0.15);
    border-radius: 8px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #757575;
    line-height: 28px;
  }
  .arrow {
    line-height: 166px;
    img {
      width: 24px;
      height: 20px;
      vertical-align: middle;
    }
  }
}
.title-description {
  margin-top: 30px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #757575;
  line-height: 24px;
}
.getInIcon {
  width: 36px;
  margin-bottom: 24px;
}

.priceBanner {
  width: 100%;
  min-height: 60px;
  position: fixed;
  bottom: 50px;
  z-index: 99;
}
.priceBannerArea {
  width: 1200px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.priceBannerContent {
  position: relative;
}
.priceBannerInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
}
.pricesTips .dollar {
  background: rgba(250, 100, 0, 0.1);
  color: #fa6400;
  padding: 4px 12px;
  display: inline-block;
  border-radius: 4px;
}
.pricesTips .huabei {
  background: rgba(10, 122, 255, 0.1);
  color: #0a7aff;
  padding: 4px 12px;
  display: inline-block;
  border-radius: 4px;
  margin-left: 10px;
}
.priceBannerInner .prices {
  padding-bottom: 0px;
  color: #ff3b30;
  font-size: 24px;
  min-height: 30px;
  .mainTitle {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 42px;
    margin-right: 30px;
  }
  .subTitle {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #757575;
    line-height: 42px;
    vertical-align: top;
    margin-right: 16px;
  }
}
.priceLeft,
.priceRight {
  display: inline-block;
}
.priceLeft {
  padding-left: 60px;
}
.priceRight {
  float: right;
  padding-right: 82px;
}
.priceRight .classHelp {
  line-height: 35px;
  background: #ff9f0a;
  color: #fff;
  border-radius: 18px;
  padding: 0 34px;
}
.priceRight .buyNow {
  line-height: 35px;
  background: #ff3b30;
  color: #fff;
  border-radius: 18px;
  padding: 0 34px;
}
.priceBannerContent img {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
}
.pricesTips {
  display: inline-block;
  margin-right: 12px;
}
.six-points {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 900px;
  margin: 0 auto;
  .points {
    width: 220px;
    padding: 40px 0;
    text-align: center;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 18px;
    border: 1px solid rgba(120, 120, 128, 0.2);
    background: #fff;
    margin: 0 40px;
    margin-bottom: 32px;
    border-radius: 8px;
    img {
      vertical-align: top;
      margin-bottom: 12px;
      width: 32px;
      height: 32px;
    }
  }
}
.hardPoint {
  width: 978px;
  padding: 30px 60px;
  padding-right: 20px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(120, 120, 128, 0.2);
  .list {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #e59f3c;
    line-height: 46px;
    li {
      list-style: disc;
    }
    list-style: disc;
  }
  .round {
    width: 58px;
    height: 58px;
    line-height: 58px;
    border-radius: 50%;
    border: 1px solid #f3bb6c;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #e59f3c;
    text-align: center;
    margin-right: 14px;
    display: inline-block;

    background: rgba(243, 187, 108, 0.2);
  }
  .rightText {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.4);
    padding: 12px 0;
    line-height: 18px;
  }
  .rightlist {
    margin-bottom: 14px;
  }
}

.provideContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  .type {
    width: 270px;
    padding: 27px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #757575;
    text-align: center;
    cursor: pointer;
    img {
      width: 20px;
      margin-bottom: 14px;
    }
    &:hover {
      border-color: rgba(10, 122, 255, 0.85) !important;
      .title,
      .description {
        color: rgba(10, 122, 255, 0.85) !important;
      }
    }
    .title {
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 27px;
      margin-bottom: 14px;
    }
    .description {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #757575;
      line-height: 21px;
      margin-bottom: 20px;
    }
    .price {
      font-size: 24px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ff3b30;
      line-height: 36px;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 30px;
}
</style>