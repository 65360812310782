// 模拟面试
import request from "../utils/request";


// 获取所有模拟面试列表
export async function getAllMockCourses() {
    try {
        return await request({
            url: `/courseMock/course`,
            method: "get"
        });
    } catch (error) {
        return {};
    }
}
// 获取模拟面试对应课包详情
export async function getMockDetail(id) {
    try {
        return await request({
            url: `/courseMock/course/${id}`,
            method: "get"
        });
    } catch (error) {
        return {};
    }
}
// 模拟面试订单是否支付
export async function mockIsNotPay(id) {
    try {
        return await request({
            url: `/order/simulationInterview/isNotPay/${id}`,
            method: "get"
        });
    } catch (error) {
        return {};
    }
}
// 创建模拟面试订单
export async function createMockOrder(id, params) {
    try {
        return await request({
            url: `/order/simulationInterview/${id}`,
            method: "post",
            params
        });
    } catch (error) {
        return {};
    }
}
// 获取模拟面试详情 个人中心
export async function getMockJobSeeker() {
    try {
        return await request({
            url: `/courseMock/jobSeeker`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}


// 获取模拟面试订单列表
export async function getMockInterviewOrderList() {
    try {
        return await request({
            url: `/courseMock/jobSeeker/mock`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}
// 更新简历
export async function updateResume(data) {
    try {
        return await request({
            url: `/courseMock/jobSeeker/updateResume`,
            method: "put",
            data
        });
    } catch (error) {
        return {};
    }
}
// 保存用户个人模拟面试数据
export async function saveUserMockData(data) {
    try {
        return await request({
            url: `/courseMock/jobSeeker`,
            method: "post",
            data
        });
    } catch (error) {
        return {};
    }
}
// 发布模拟面试评论
export async function publishMockComment(data) {
    try {
        return await request({
            url: `/courseMock/jobSeeker/comment`,
            method: "put",
            data
        });
    } catch (error) {
        return {};
    }
}
// 获取模拟面试状态
export async function getMockStatus(id) {
    try {
        return await request({
            url: `/courseMock/${id}`,
            method: "get"
        });
    } catch (error) {
        return {};
    }
}
// 确认模拟面试时间
export async function confirmMockInterviewTime(data) {
    try {
        return await request({
            url: `/courseMock/jobSeeker/acknowledgingTime`,
            method: "put",
            data
        });
    } catch (error) {
        return {};
    }
}

// 联系面试官
export async function switchMockMatching(id) {
    try {
        return await request({
            url: `/courseMock/jobSeeker/matching/${id}`,
            method: "put",
        });
    } catch (error) {
        return {};
    }
}
// 获取模拟面试列表 面试官
export async function getMockList(params) {
    try {
        return await request({
            url: `/courseMock/interviewer/matching`,
            method: "get",
            params
        });
    } catch (error) {
        return {};
    }
}
// 获取我的面试人列表
export async function getMyInterviewMockList(params) {
    try {
        return await request({
            url: `/courseMock/interviewer/mock`,
            method: "get",
            params
        });
    } catch (error) {
        return {};
    }
}
// 获取历史面试列表
export async function getHistoryMockList(params) {
    try {
        return await request({
            url: `/courseMock/interviewer/history`,
            method: "get",
            params
        });
    } catch (error) {
        return {};
    }
}
// 接受面试
export async function acceptMock(id) {
    try {
        return await request({
            url: `/courseMock/interviewer/accept/${id}`,
            method: "put",
        });
    } catch (error) {
        return {};
    }
}
// 获取面试官详情
export async function getMockInterviewer() {
    try {
        return await request({
            url: `/courseMock/interviewer`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}
// 保存面试官详情
export async function saveMockInterviewer(data) {
    try {
        return await request({
            url: `/courseMock/interviewer`,
            method: "post",
            data
        });
    } catch (error) {
        return {};
    }
}
// 确认面试时间范围
export async function confirmInterviewTimeRange(data) {
    try {
        return await request({
            url: `/courseMock/interviewer/acknowledgingTime`,
            method: "put",
            data
        });
    } catch (error) {
        return {};
    }
}
// 保存面试结果
export async function saveInterviewResults(data) {
    try {
        return await request({
            url: `/courseMock/interviewer/result`,
            method: "put",
            data
        });
    } catch (error) {
        return {};
    }
}
// 结束面试
export async function endInterview(id) {
    try {
        return await request({
            url: `/courseMock/interviewer/accomplish/${id}`,
            method: "put",
        });
    } catch (error) {
        return {};
    }
}